import {
  StyledContainer,
  StyledImage,
  StyledTileContent,
  StyledTileLabel,
} from './style';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

interface TileProps {
  thumbnail: {
    src: string;
    width?: number;
    height?: number;
    alt?: string;
  };
  className?: string;
  children: [ReactElement<TileProps>, ...ReactNode[]];
}

function Tile({ thumbnail, children, className }: Readonly<TileProps>) {
  const [label, ...rest] = children;
  return (
    <StyledContainer className={className}>
      <StyledImage
        src={thumbnail.src}
        width={thumbnail.width}
        height={thumbnail.height}
        alt={thumbnail.alt}
      />
      {label}
      <StyledTileContent>{rest}</StyledTileContent>
    </StyledContainer>
  );
}

export enum TileLabelColor {
  BLUE,
  WHITE,
  GREY,
}

type TileLabelProps = {
  color: TileLabelColor;
};

const TileLabel = ({
  color = TileLabelColor.WHITE,
  children,
}: PropsWithChildren<TileLabelProps>) => (
  <StyledTileLabel $color={color}>{children}</StyledTileLabel>
);

Tile.TileLabel = TileLabel;
export default Tile;
