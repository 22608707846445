'use client';

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import Heading from '@app/core/components/typography/heading';
import { Paragraph } from '@app/core/components/typography';
import { AccordionVariant } from '@app/core/components/accordion/accordion-item/cont';

interface Props {
  $isActive?: boolean;
  $variant?: AccordionVariant;
}

const StyledAccordion = styled.div<Props>`
  width: 100%;
  background: ${({ $variant, theme: { COLOR } }) =>
    $variant === AccordionVariant.LIGHT
      ? COLOR.NEUTRALS.ULTRA_LIGHT_GREY
      : COLOR.NEUTRALS.BLACK};
  overflow: hidden;
`;

const StyledAccordionButton = styled.button<Props>`
  width: 100%;
  cursor: default;
  margin: 0;
  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.DARK_GREY};
  border: none;
  padding: 0.8rem;
  position: relative;

  &:after {
    content: '';
    border: 7.5rem solid transparent;
    border-top: 1.6rem solid
      ${({ theme: { COLOR } }) => COLOR.NEUTRALS.DARK_GREY};
    width: 15rem;
    position: absolute;
    bottom: -9.1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const StyledAccordionTitle = styled(Heading)<Props>`
  width: 100%;
  text-align: center;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: ${({ $variant, theme: { COLOR } }) =>
    $variant === AccordionVariant.LIGHT
      ? COLOR.NEUTRALS.WHITE
      : COLOR.NEUTRALS.GREY};
`;

const StyledAccordionBody = styled(motion.div)`
  overflow: hidden;
`;

const StyledAccordionContainer = styled.div`
  padding-top: 1.6rem;
`;

const StyledBadge = styled(Paragraph)`
  display: block;
  color: ${({ theme }) => theme.COLOR.NEUTRALS.WHITE};
  text-align: center;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2.2rem;
`;

export {
  StyledAccordion,
  StyledAccordionTitle,
  StyledAccordionContainer,
  StyledAccordionBody,
  StyledAccordionButton,
  StyledBadge,
};
