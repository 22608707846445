import { RankTitle } from '@app/core/components/rank-image';

export function calculateRank(score: number, maxScore: number) {
  const percentage = (score / maxScore) * 100;

  switch (true) {
    case percentage > 80: {
      return RankTitle.champ;
    }
    case percentage > 60: {
      return RankTitle.star;
    }
    case percentage > 40: {
      return RankTitle.pro;
    }
    case percentage > 20: {
      return RankTitle.challenger;
    }
    default: {
      return RankTitle.rookie;
    }
  }
}
