import { ReactElement } from 'react';

import { StaticImageData } from 'next/image';
import {
  StyledContainer,
  StyledImage,
} from '@app/core/components/rank-image/style';
import TransparentCan from './transp-jerrycan.png';
import GoldCan from './gold-jerrycan.png';
import BlueCan from './blue-jerrycan.png';
import BlackCan from './black-jerrycan.png';
import SilverCan from './silver-jerrycan.png';
import Lock from './lock-lock.png';

export enum RankTitle {
  champ,
  star,
  pro,
  challenger,
  rookie,
  locked,
}

const rankImages = {
  [RankTitle.champ]: GoldCan,
  [RankTitle.star]: SilverCan,
  [RankTitle.pro]: BlueCan,
  [RankTitle.challenger]: BlackCan,
  [RankTitle.rookie]: TransparentCan,
  [RankTitle.locked]: Lock,
} satisfies Record<RankTitle, StaticImageData>;

interface Props {
  rankTitle: RankTitle;
  className?: string;
}

function RankImage({ rankTitle, className }: Readonly<Props>): ReactElement {
  return (
    <StyledContainer className={className}>
      <StyledImage
        $rankTitle={rankTitle}
        src={rankImages[rankTitle]}
        alt={'test'}
        height={500}
        width={200}
      />
    </StyledContainer>
  );
}

export default RankImage;
