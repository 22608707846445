import useCalculateImageSrc from '@app/core/hooks/use-calculate-image-src';
import { ImageContainer, StyledImage } from '@app/core/components/image/style';

export interface IImageProps extends Partial<HTMLImageElement> {
  src: string;
  width?: number;
  height?: number;
  isCovered?: boolean;
  loader?: ({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality?: number;
  }) => string;
  alt?: string;
  className?: string;
  quality?: number;
  loadingMethod?: 'lazy' | 'eager';
}

const contentfulImageLoader = ({
  src,
  quality,
  width,
}: {
  src: string;
  width: number;
  quality?: number;
}): string => `${src}?w=${width}&fm=webp&q=${quality}`;

export default function Image({
  src,
  width,
  height,
  isCovered = false,
  loader = contentfulImageLoader,
  alt = '...',
  className,
  quality = 75,
  loadingMethod = 'lazy',
}: Readonly<IImageProps>) {
  const { imgSrc, srcSet, sizes } = useCalculateImageSrc({
    src,
    loader,
    width,
    quality,
  });

  return (
    <ImageContainer className={className}>
      <StyledImage
        src={imgSrc}
        alt={alt}
        width={width}
        height={height}
        loading={loadingMethod}
        srcSet={srcSet}
        sizes={sizes}
        $isCovered={isCovered}
        className={className}
      />
    </ImageContainer>
  );
}
