'use client';

import styled, { css } from 'styled-components';
import Link from '@app/core/components/link';
import Button from '@app/core/components/button';
import { motion } from 'framer-motion';

interface StyledMenuProps {
  $isOpen: boolean;
}

export const StyledMenu = styled(motion.div)<StyledMenuProps>`
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.Z_INDEX.NAVIGATION};
`;

export const StyledContainer = styled.div`
  background: ${({ theme: { COLOR } }) => COLOR.NEUTRALS.DARK_GREY};
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 5rem;
  right: 0;
  color: ${({ theme: { COLOR } }) => COLOR.BLUE.ACCENT};
  span {
    width: 2.4rem;
    height: 2.4rem;
  }
`;
export const StyledNav = styled.nav`
  position: relative;
  padding: 5rem 0;
`;

export const StyledList = styled.ul`
  margin-top: 6.6rem;
`;

export const StyledListItem = styled.li`
  margin: 2.4rem 0;
`;

export const StyledLink = styled(Link)`
  font-family: ${({ theme: { FONT } }) => FONT.SECONDARY};
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: ${({ theme: { COLOR } }) => COLOR.BLUE.ACCENT};

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.SM}px) {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
`;

export const StyledOverlay = styled.div<StyledMenuProps>`
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(32, 32, 32, 0.4);
  backdrop-filter: blur(1.5px);
  z-index: ${({ theme }) => theme.Z_INDEX.OVERLAY};
  transition: opacity ${({ theme }) => theme.TRANSITION.TRANSITION_EASE_IN_OUT};
`;

export const StyledLanguageSelectorLabel = styled.label(
  ({ theme: { TYPOGRAPHY, COLOR } }) => {
    return css`
      ${TYPOGRAPHY.HEADING_6};
      color: ${COLOR.NEUTRALS.WHITE};
      display: block;
    `;
  }
);
export const StyledLanguageSelector = styled.select(
  ({ theme: { TYPOGRAPHY, COLOR } }) => {
    return css`
      ${TYPOGRAPHY.BODY_SM_BOLD};
      padding: 1.6rem 1.6rem 1.6rem 6rem;
      margin-top: 1.6rem;
      height: 5.6rem;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      border-width: 1px;
      background: transparent;
      color: ${COLOR.NEUTRALS.WHITE};
      border-color: ${COLOR.NEUTRALS.WHITE};
      border-image: ${COLOR.GRADIENTS.BLUE_SECONDARY} 10;
      border-style: solid;
      appearance: none;
      background-image: url(https://cdn.mpmoil.com/img/buttons/select-bg.png);
      background-repeat: no-repeat;
    `;
  }
);
