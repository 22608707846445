import {
  StyledOneliner,
  StyledOneLinerColumn,
} from 'src/core/components/oneliner/style';
import { Container, Row } from '@app/core/components/layout';

export default function OneLiner() {
  return (
    <Container>
      <Row>
        <StyledOneLinerColumn columns={{ XXS: 12 }}>
          <StyledOneliner />
        </StyledOneLinerColumn>
      </Row>
    </Container>
  );
}
