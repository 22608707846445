'use client';

import styled, { css } from 'styled-components';

interface ImageProps {
  $isCovered: boolean;
}

export const ImageContainer = styled.span`
  display: block;
  position: relative;
  width: 100%;
`;

export const StyledImage = styled.img<ImageProps>(
  ({ $isCovered }) => css`
    display: flex;
    width: 100%;
    height: auto;

    ${$isCovered &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    `}
  `
);
