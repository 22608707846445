import { useEffect, useRef, RefObject } from 'react';

type UseKeyTrapOptions = {
  onEscape?: (event: KeyboardEvent) => void;
};

/**
 * A hook to trap the keyboard navigation to a specific component
 *
 * @param {boolean} shouldTrap Toggle this to enable and disable trapping
 * @param {UseKeyTrapOptions} options Allows you to add a callback that will be executed when the escape button is pressed
 * @return {RefObject} RefObject to be placed on the component to which you want the keyboard navigation to be trapped.
 *
 * @example
 * function Menu() {
 *   const [isMenuOpen, toggleIsMenuOpen, setIsMenuOpen] = useToggle(false)
 *   const menuRef = useKeyTrap(isMenuOpen, {onEscape:() => {setIsMenuOpen(false)}})
 *
 *   return (
 *     <div>
 *       <button onClick={() => {toggleIsMenuOpen()}}>Toggle Menu</button>
 *       <nav className={`nav ${isMenuOpen ? 'open' : 'closed'}`} ref={menuRef}>
 *         <li><a href='/'>Home</a></li>
 *       </nav>
 *     </div>
 *   )
 * };
 */
export default function useKeyTrap<T extends HTMLElement>(
  shouldTrap: boolean,
  options: UseKeyTrapOptions
): RefObject<T> {
  const keyTrapRef = useRef<T>(null);

  useEffect(() => {
    if (!shouldTrap) {
      return undefined;
    }

    const menuElement = keyTrapRef.current;
    const focusableElements = menuElement?.querySelectorAll<
      | HTMLButtonElement
      | HTMLAnchorElement
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement
    >(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements?.[0];
    const lastElement =
      focusableElements?.[(focusableElements?.length || 0) - 1];

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') {
        return;
      }

      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement?.focus();
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement?.focus();
      }
    };

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return;
      }

      options?.onEscape?.(event);
    };

    menuElement?.addEventListener('keydown', handleTabKeyPress);
    menuElement?.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      menuElement?.removeEventListener('keydown', handleTabKeyPress);
      menuElement?.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [shouldTrap, options, keyTrapRef]);

  return keyTrapRef;
}
