import { useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Context as ColumnContext } from '@app/core/components/layout';

export const getSRCSetItem = ({
  src,
  displayedWidth,
  quality,
  requestedWidth,
  loader,
}: {
  src: string;
  displayedWidth: number;
  quality: number | undefined;
  requestedWidth: number | undefined;
  loader: ({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality?: number;
  }) => string;
}): string =>
  !requestedWidth || displayedWidth <= requestedWidth
    ? `${loader({ src, width: displayedWidth, quality })} ${displayedWidth}w`
    : '';

export const getMediaQuery = ({
  currentBreakpointWidth,
  nextBreakpointWidth,
}: {
  currentBreakpointWidth: number;
  nextBreakpointWidth: number | undefined;
}): string =>
  `(min-width: ${currentBreakpointWidth}px)${
    nextBreakpointWidth ? ` and (max-width: ${nextBreakpointWidth}px)` : ''
  }`;

const DEFAULT_BREAKPOINT = 'XXS';

interface ICalculateImageSrcProps {
  src: string;
  loader: ({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality?: number;
  }) => string;
  width?: number;
  quality: number | undefined;
}

type TImageSourceOptions = {
  imgSrc: string;
  srcSet?: string;
  sizes?: string;
};

const useCalculateImageSrc = ({
  src,
  width,
  quality,
  loader,
}: ICalculateImageSrcProps): TImageSourceOptions => {
  const columns = useContext(ColumnContext);

  const {
    LAYOUT: { LAYOUT_MAX_CONTAINER_WIDTH },
  } = useTheme();

  return useMemo(() => {
    const isColumnsDefined = Object.values(columns).every(
      (columnBreakpoint) =>
        columnBreakpoint.maximumColumnWidth !== undefined &&
        columnBreakpoint.breakpointValue !== undefined
    );

    const defaultMaximumColumnWidth = isColumnsDefined
      ? columns[DEFAULT_BREAKPOINT].maximumColumnWidth
      : LAYOUT_MAX_CONTAINER_WIDTH;

    const srcSet = isColumnsDefined
      ? Object.values(columns)
          .map(({ maximumColumnWidth }) => {
            const displayedImageSize: number = maximumColumnWidth!;

            const firstSRCSetItem = getSRCSetItem({
              src,
              displayedWidth: displayedImageSize,
              quality,
              requestedWidth: width,
              loader,
            });

            const secondSRCSetItem = getSRCSetItem({
              src,
              displayedWidth: displayedImageSize * 2,
              quality,
              requestedWidth: width,
              loader,
            });
            return `${firstSRCSetItem}${
              secondSRCSetItem && `, ${secondSRCSetItem}`
            }`;
          })
          .join(', ')
          .concat(width ? `${loader({ src, width, quality })} ${width}w` : '')
      : undefined;

    const sizes = isColumnsDefined
      ? Object.values(columns)
          .map(
            ({ breakpointValue, maximumColumnWidth, nextBreakpointWidth }) => {
              const displayedImageSize: number = maximumColumnWidth || 0;

              return `${getMediaQuery({
                currentBreakpointWidth: breakpointValue || 0,
                nextBreakpointWidth,
              })} ${displayedImageSize}px`;
            }
          )
          .join(', ')
      : undefined;

    return {
      imgSrc: loader({ src, width: defaultMaximumColumnWidth || 0, quality }),
      srcSet,
      sizes,
    };
  }, [columns, LAYOUT_MAX_CONTAINER_WIDTH, width, loader, src, quality]);
};

export default useCalculateImageSrc;
