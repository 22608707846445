import { z } from 'zod';

export const ImageSchema = z.object({
  fields: z.object({
    title: z.string(),
    file: z.object({
      url: z.string(),
      details: z.object({
        image: z.object({
          width: z.number(),
          height: z.number(),
        }),
      }),
    }),
  }),
});

export function createContentfulContentModel<
  ContentType extends z.ZodLiteral<string>,
  Fields extends z.ZodTypeAny,
>(contentType: ContentType, fields: Fields) {
  return z.object({
    sys: z.object({
      id: z.string(),
      contentType: z.object({
        sys: z.object({
          type: z.literal('Link'),
          linkType: z.literal('ContentType'),
          id: contentType,
        }),
      }),
    }),
    fields,
  });
}

export type ContentfulCollection<ContentModel> = {
  total: number;
  skip: number;
  limit: number;
  items: ContentModel[];
};
export const ConfigSchema = createContentfulContentModel(
  z.literal('config'),
  z.object({
    translatableKeys: z.record(z.string(), z.string()),
  })
);
export type Config = z.infer<typeof ConfigSchema>;
