'use client';

import { ElementType, ReactNode, useEffect, useId } from 'react';
import {
  StyledAccordion,
  StyledAccordionBody,
  StyledAccordionButton,
  StyledAccordionContainer,
  StyledAccordionTitle,
  StyledBadge,
} from './style';
import { useToggle } from 'usehooks-ts';
import {
  AccordionVariant,
  InitialAccordionItemStates,
} from '@app/core/components/accordion/accordion-item/cont';
import { HeadingTypes } from '@app/core/theme/basic/const';

interface Props {
  title?: ReactNode;
  className?: string;
  isActive?: boolean;
  variant?: AccordionVariant;
  children?: ReactNode;
  initialState?: InitialAccordionItemStates;
  badge?: string | number;
  titleLevel?: ElementType;
}

function AccordionItem({
  title,
  isActive = false,
  children,
  variant = AccordionVariant.LIGHT,
  className,
  initialState = InitialAccordionItemStates.CLOSED,
  badge,
  titleLevel = 'h1',
}: Readonly<Props>) {
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(
    !isActive ? true : !!initialState
  );
  const panelId = useId();
  const buttonId = useId();

  useEffect(() => {
    setIsOpen(!isActive ? true : !!initialState);
  }, [isActive, initialState, setIsOpen]);

  const animation = {
    initial: isOpen ? 'open' : 'collapsed',
    animate: isOpen ? 'open' : 'collapsed',
    variants: {
      open: {
        opacity: 1,
        height: 'auto',
      },
      collapsed: {
        opacity: 0,
        height: 0,
        transitionEnd: {
          display: 'none',
        },
      },
    },
    transition: {
      ease: [0.45, 0, 0.55, 1],
      duration: 0.4,
    },
  };

  return (
    <StyledAccordion className={className} $variant={variant}>
      <StyledAccordionButton
        $variant={variant}
        type="button"
        disabled={!isActive}
        onClick={toggleIsOpen}
        $isActive={isActive}
        aria-expanded={isOpen}
        id={buttonId}
        aria-controls={panelId}
      >
        <StyledAccordionTitle
          type={HeadingTypes.HEADING_6}
          $variant={variant}
          forwardedAs={titleLevel}
        >
          {title}
        </StyledAccordionTitle>
        {!!badge && <StyledBadge>{badge}</StyledBadge>}
      </StyledAccordionButton>
      <StyledAccordionBody
        {...animation}
        id={panelId}
        role="region"
        aria-labelledby={buttonId}
      >
        <StyledAccordionContainer>{children}</StyledAccordionContainer>
      </StyledAccordionBody>
    </StyledAccordion>
  );
}

export default AccordionItem;
